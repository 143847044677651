
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        









































































































































$categories-height: 9.6rem;
$bp: m;

.magazine-tv {
  margin-top: calc(var(--header-height-down) + #{$categories-height});
}

.magazine-tv__hero {
  position: relative;
  display: flex;
  flex-direction: column-reverse;

  @include mq($bp) {
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: center;
  }
}

.magazine-tv__hero__content {
  position: relative;
  z-index: 1;
  margin-top: $spacing;

  @include mq($bp) {
    flex: col(5.5, 10) 0 0;
    margin: 0;
  }

  @include mq(xl) {
    flex: col(6, 10) 0 0;
  }
}

.magazine-tv__hero__picture {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: auto;
  aspect-ratio: 4/3;
  background: $c-dark-grey;
  border-radius: 1.6rem;

  @include mq($bp) {
    flex: 1;
    align-self: stretch;
    aspect-ratio: auto;
    margin-right: $spacing * 1.5;

    &::after {
      content: none;
    }
  }

  @include mq(xl) {
    margin-right: col(0.5, 10);
  }
}

.magazine-tv__flexible > * {
  @include default-margin(0.6);
}
