
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        
































































































































































































/* stylelint-disable declaration-no-important */
$bp: 'l';
$button-width: 7rem;

.magazine-categories {
  position: fixed;
  z-index: 3;
  top: var(--header-height);
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-height: 10rem;
  background: $c-white;
  border-top: 1px solid $c-light-grey;
  border-bottom: 1px solid $c-light-grey;
  transition: 0.2s $ease-softer;

  .magazine-landing & {
    position: sticky;
  }

  .header.down + * & {
    top: var(--header-height-down);

    &.is-sticky {
      box-shadow: $card-shadow;
    }
  }

  @include mq($bp) {
    .magazine-landing & {
      top: var(--header-height-down);
      margin-top: -5rem;

      &:not(.is-sticky) {
        background: none;
        border: 0;
        box-shadow: none;
      }
    }
  }
}

.magazine-categories__list-outer {
  @include scrollbars(0, $c-light-grey);

  overflow-y: auto;
  margin-top: 0;
  margin-bottom: 0;
  padding: $spacing * 0.25 !important; // .wrapper
  list-style-type: none;

  &::-webkit-scrollbar {
    height: 4px;
  }

  @include mq($until: $bp) {
    width: fit-content !important; // .wrapper
  }

  @include mq($bp) {
    overflow: visible;
    width: fit-content !important; // .wrapper
    padding: $spacing * 0.5 !important; // .wrapper
  }
}

.magazine-categories__list {
  @extend %list-nostyle;

  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  min-width: fit-content;
  margin-top: 0;
  margin-bottom: 0;

  @include mq($bp) {
    gap: $spacing * 0.5;

    .magazine-landing & {
      padding: $spacing * 0.5 $spacing * 0.75 !important; // wrapper
      background: $c-white;
      transition: 0.1s;
    }

    .magazine-landing .magazine-categories:not(.is-sticky) & {
      width: fit-content !important; // wrapper
      border-radius: 8px;
      box-shadow: $card-shadow;
    }
  }
}

.magazine-categories__item-outer {
  @include mq($bp) {
    flex-shrink: 0;
  }
}

.magazine-categories__item {
  @extend %text-center;

  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 7rem;
  max-width: 10rem;
  padding: $spacing * 0.5;
  font-size: 1.3rem;
  line-height: 1;
  text-decoration: none;
  transition: color 0.2s $ease-softer;

  .icon {
    box-sizing: content-box;
    padding: 0.6rem;
    background: $c-white;
    border-radius: 50%;
    transition: 0.2s $ease-softer;
    transition-property: color, background;
  }

  &.selected,
  &.router-link-exact-active {
    color: $c-mustard;

    .icon {
      color: $c-white;
      background: $c-mustard;
    }
  }

  @include mq($bp) {
    max-width: none;
    padding: $spacing * 0.5 $spacing * 0.75;

    .icon {
      margin-bottom: $spacing * 0.25;
    }
  }

  @media (pointer: fine) {
    &:hover,
    &:focus-visible {
      color: $c-mustard;

      .icon {
        color: $c-white;
        background: $c-mustard;
      }
    }
  }
}

[class*='magazine-categories__button--'] {
  @extend %button-nostyle;

  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: $button-width;
  height: 100%;
  padding: $spacing * 0.25;
  color: $c-dark-grey;
  text-align: left;
  background: linear-gradient(90deg, $c-white 50%, rgba($c-white, 0) 80%);
  transition: opacity 0.2s $ease-softer;

  &[class*='--right'] {
    right: 0;
    left: initial;
    text-align: right;
    background: linear-gradient(270deg, $c-white 50%, rgba($c-white, 0) 80%);
  }

  &.hidden {
    opacity: 0;
    pointer-events: none;
  }

  @include mq($bp) {
    display: none;
  }
}
